import { useEffect, useState, useRef} from 'react'
import axios from 'axios'
import {
    Card,
    CardContent,
    CardActions,
    CardHeader,
    Grid,
    TextField,
    Snackbar
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '../../components/Alert'
import {_myApi} from '../../Api/ApiUrl'
import {ResponsiveDrawer} from '../Layouts/ResponsiveTemplate'

var querystring = require('querystring');

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    Buttonleft:{
        align: 'right'
    },
    formControl: {
        minWidth: '100%',
    }
  });

export function Profile(){
    const classes = useStyles()
    const _isMounted = useRef(true);//check mounted

    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [alert, setAlert] = useState("success")
    const [mssg, setMssg] = useState("")

    const [id, setId] = useState("")
    const [name,setName] = useState("")
    const [username,setUsername] = useState("")
    const [email,setEmail] = useState("")
    const [phone,setPhone] = useState("")
    const [profile,setProfile] = useState("")
    const loadData = async() => { 
        
        await axios.get( _myApi + '/myuser', {
            headers:{ 
              "x-access-token": localStorage.getItem("token")
            }
          }).then(response => {
                if(_isMounted.current){
                //   const info = Object.values(response.data.data);
                    if(response.data.status){
                        const info = response.data.data
                        console.log(info.id);
                        setId(info.id)
                        setName(info.fullname)
                        setUsername(info.user)
                        setEmail(info.email)
                        setPhone(info.phone)
                        setProfile(info.profiles.name)
                    }
                }
            }).catch(err => {
                console.log(err);
            })
    };

    const handleUpdate = async(e) => {
        e.preventDefault()
        await axios.put( _myApi + "/user/"+id,querystring.stringify({
          name: name,
          username: username,
          email: email,
          phone: phone,
        }),{
          headers:{ 
            "Content-Type": "application/x-www-form-urlencoded",
            "x-access-token": localStorage.getItem("token")
          }
        }).then((response) => {
            if(_isMounted.current){
              if(response.data.status === true){
                  setAlert('success')
                  setMssg('El viaje ha sido correctamente actualizado.')
                  handleOpenSnackBar()
              }else{
                  console.log(response);
                  setMssg('Error al actualizar datos del viaje.')
                  setAlert('error')
                  handleOpenSnackBar()
              }
            }
            
          }).catch(err =>{
            console.log(err);
            setMssg('Error al actualizar datos del viaje.')
            setAlert('error')
            handleOpenSnackBar()
  
          });
      }

    useEffect(()=>{
        loadData()
        return () => {
            _isMounted.current = false;  // not good
          }
    }, [])

    const handleOpenSnackBar = () => { setOpenSnackBar(true); };
    
    const handleCloseSnackBar = () => { setOpenSnackBar(false); };

    return(
        <>
        <ResponsiveDrawer>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity={alert}>
            {mssg}
          </Alert>
        </Snackbar>
        <Grid container>
            <Grid item xs={12} sm={8}>
            <Card className={classes.root}>
                <CardHeader
                    title="Mi perfil"
                />
                <CardContent>
                <form id="updateform" className={classes.form} noValidate onSubmit={handleUpdate}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="fname"
                            name="firstName"
                            variant="outlined"
                            fullWidth
                            id="firstName"
                            label="Nombre y Apellido"
                            autoFocus
                            value={name}
                            onChange={(e) => {setName(e.target.value)}}
                            InputProps={{
                                readOnly: true,
                              }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="username"
                            label="Nombre de usuario"
                            name="usernamer"
                            value={username}
                            onChange={(e) => {setUsername(e.target.value)}}
                            InputProps={{
                                readOnly: true,
                              }}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="email"
                            label="Correo electronico"
                            name="email"
                            autoComplete="email"
                            type='email'
                            value={email}
                            onChange={(e) => {setEmail(e.target.value)}}
                            InputProps={{
                                readOnly: true,
                              }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="phone"
                            label="Telefono"
                            name="phone"
                            autoComplete="phone"
                            value={phone}
                            onChange={(e) => {setPhone(e.target.value)}}
                            InputProps={{
                                readOnly: true,
                              }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="profile"
                            label="Perfil"
                            name="profile"
                            value={profile}
                            onChange={(e) => {setProfile(e.target.value)}}
                            InputProps={{
                                readOnly: true,
                              }}
                        />
                        </Grid>
                    </Grid>
                    
                    </form>
                </CardContent>
                <CardActions style={{justifyContent: 'right'}} spacing={2}>
                   
                </CardActions>
            </Card>
        </Grid>
        </Grid>
        </ResponsiveDrawer>
        </>
    )
}