import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {_myApi} from '../../Api/ApiUrl'

export function CitySelect({stateid}) {
    const _isMounted = useRef(true)
    const [state,setState] = useState("")
    const [city, setCity] = useState([])

    useEffect( () => {
        if(stateid){
            setState(stateid)
        }

        const getCity= async () =>{
            await axios.get( _myApi + '/city/'+ state, {
                headers:{ 
                  "x-access-token": localStorage.getItem("token")
                }
              }).then(response => {
                    if(response){
                        setCity(response.data.data)
                    }
                }).catch(err => {
                    console.log(err);
                })
        }
        if(state){
            getCity()
        }
        return () => {
            _isMounted.current = false;  
          }
    }, [state, stateid] )

    return (
        <>
            {city.map( res =>{
               return <option key={res.id} value={res.id}>{res.name}</option>
            })}
        </>
    )
}