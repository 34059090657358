import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { ExitToApp, LocalShipping, LocalGasStation, AccountBox, CardTravel, Build} from '@material-ui/icons';
import PeopleIcon from '@material-ui/icons/People';
import { Link } from 'react-router-dom';

export const mainListItems = (
  <div>
    <ListItem button component={Link} to={'/'}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button component={Link} to={'/fuelcharge'}>
      <ListItemIcon>
        <LocalGasStation />
      </ListItemIcon>
      <ListItemText primary="Recarga combustible" />
    </ListItem>
    <ListItem button component={Link} to={'/trip'}> 
      <ListItemIcon>
        <CardTravel />
      </ListItemIcon>
      <ListItemText primary="Viajes" />
    </ListItem>
    <ListItem button component={Link} to={'/changecomponents'}>
      <ListItemIcon>
        <Build />
      </ListItemIcon>
      <ListItemText primary="Reparaciones" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Administrador</ListSubheader>

      <ListItem button component={Link} to={'/user'}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Usuarios" />
      </ListItem>
   
      <ListItem button component={Link} to={'/truck'}>
        <ListItemIcon>
          <LocalShipping />
        </ListItemIcon>
        <ListItemText primary="Vehiculos" />
      </ListItem>
  
  </div>
);

export const settingsListItems = (
  <div>
    <ListSubheader inset>Opciones</ListSubheader>

      <ListItem button component={Link} to={'/myprofile'}>
        <ListItemIcon>
          <AccountBox />
        </ListItemIcon>
        <ListItemText primary="Perfil" />
      </ListItem>
   
      <ListItem button component={Link} to={'/logout'}>
        <ListItemIcon>
          <ExitToApp/>
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </ListItem>
  </div>
);