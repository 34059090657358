import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {_myApi} from '../../Api/ApiUrl'

export function CountrySelect() {
    const _isMounted = useRef(true)
    const [country, setCountry] = useState([])

    useEffect( () => {
        const getCountry= async () =>{
            await axios.get( _myApi + '/country', {
                headers:{ 
                  "x-access-token": localStorage.getItem("token")
                }
              }).then(response => {
                    if(response){
                        setCountry(response.data.data)
                    }
                }).catch(err => {
                    console.log(err);
                })
        }
        getCountry()
        return () => {
            _isMounted.current = false;  
          }
    }, [] )

    return (
        <>
            {country.map( res =>{
               return <option key={res.id} value={res.id}>{res.name}</option>
            })}
        </>
    )
}