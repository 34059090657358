import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {_myApi} from '../../Api/ApiUrl'

export function UserSelect() {
    const _isMounted = useRef(true)
    const [users, setUsers] = useState([])

    useEffect( () => {
        const getUser = async () =>{
            await axios.get( _myApi + '/user', {
                headers:{ 
                  "x-access-token": localStorage.getItem("token")
                }
              }).then(response => {
                    if(response){
                        const info = Object.values(response.data.data);
                        setUsers(info)
                    }
                }).catch(err => {
                    console.log(err);
                })
        }
        getUser()
        return () => {
            _isMounted.current = false;  
          }
    }, [] )

    return (
        <>
            {users.map( res =>{
               return <option key={res.id} value={res.id}>{res.fullname}</option>
            })}
        </>
    )
}

