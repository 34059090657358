import { forwardRef, useEffect, useState, useRef, lazy,Suspense } from "react"
import axios from 'axios'
import { useHistory} from 'react-router-dom'

import { 
  Button, 
  TextField, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  Grid,
  InputLabel,
  Select,
  FormControl,
  Snackbar,
  useTheme
} from '@material-ui/core'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Alert } from '../../components/Alert'
import {_myApi} from '../../Api/ApiUrl'
import {ResponsiveDrawer} from '../Layouts/ResponsiveTemplate'
const ProfileSelect = lazy(()=> import('../../components/Selects/ProfileSelect'))
var querystring = require('querystring');

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
const columnas=[
    {
        title:'Nombre',
        field:'fullname'
    },{
        title:'Usuario',
        field:'user'
    },{
        title:'Email',
        field:'email'
    },{
        title:'Fono',
        field:'phone'
    },{
      title:'Perfil',
      field:'profiles.name'
  }
];

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function UserList() {
    const classes = useStyles();
    const _isMounted = useRef(true);//check mounted
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let history = useHistory()

    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [alert, setAlert] = useState("success")
    const [mssg, setMssg] = useState("")

    const [name,setName] = useState("")
    const [lastname,setLastname] = useState("")
    const [username,setUsername] = useState("")
    const [email,setEmail] = useState("")
    const [phone,setPhone] = useState("")
    const [pass,setPass] = useState("")
    const [repass,setRePass] = useState("")
    const [profile,setProfile] = useState("")
    

    const getAllUsers = async() => {
        await axios.get( _myApi + '/user', {
            headers:{ 
              "x-access-token": localStorage.getItem("token")
            }
          }).then(response => {
                if(_isMounted.current){
                  const info = Object.values(response.data.data);
                  setData(info);
                }
            })
    }

    const handleCreateUser = async(e) => {
      e.preventDefault()
      if(pass){
        if(pass === repass){
          await axios.post( _myApi + "/user",querystring.stringify({
            name: name,
            lastname: lastname,
            username: username,
            email: email,
            phone: phone,
            password: pass,
            repassword: repass,
            profile: profile
          }),{
            headers:{ 
              "Content-Type": "application/x-www-form-urlencoded",
              "x-access-token": localStorage.getItem("token")
            }
          }).then((response) => {
            if(_isMounted.current){
              if(response.data.status === true){
                getAllUsers();
                handleCloseDialog();
                setMssg('El usuario ha sido creado exitosamente.')
                setAlert('success')
                handleOpenSnackBar();
              }else{
                console.log(response.data);
                setMssg('Error: ' + response.data.err)
                setAlert('error')
                handleOpenSnackBar();
              }
            }
            
          }).catch(err =>{
            console.log(err);
            setMssg('Error: revise que el formulario este debidamente completado.')
            setAlert('error')
            handleOpenSnackBar();
          });
        }else{
          setMssg('Error: Las contraseñas no coinciden.')
          setAlert('error')
          handleOpenSnackBar();
        }
      }
    }
    useEffect(() => {
        getAllUsers();
        return () => {
          _isMounted.current = false;  // not good
        }
    },[])

    const handleClickOpenDialog = () => { setOpenDialog(true); };
    
    const handleCloseDialog = () => { setOpenDialog(false); };

    const handleOpenSnackBar = () => { setOpenSnackBar(true); };
    
    const handleCloseSnackBar = () => { setOpenSnackBar(false); };

    const handleOnRowClick = (event, rowData) => { 
      history.push({pathname:'/user/'+rowData.id, data: rowData})
    };

    
    return(
        <> 
        <Suspense fallback={<div>loading...</div>}>
        <ResponsiveDrawer>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity={alert}>
            {mssg}
          </Alert>
        </Snackbar>
        <MaterialTable
        title=" "
        icons={tableIcons}
        columns={columnas}
        data={data}
        onRowClick={handleOnRowClick}
        actions={[
            {
              icon: () => <AddBox color="primary"/>,
              tooltip: "Crear Usuario",
              position: "toolbar",
              onClick: () => {
                handleClickOpenDialog();
              }
            }
          ]}
        ></MaterialTable>
        {/* Create Modal */}
        <Dialog fullScreen={fullScreen} open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Crear Usuario</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Complete el siguiente formulario para poder crear un Usuario
            </DialogContentText>
            <form className={classes.form} autoComplete="off"  onSubmit={handleCreateUser}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="Nombre"
                    autoFocus
                    inputProps={{
                      maxLength: 15
                    }}
                    value={name}
                    onChange={(e) => {setName(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Apellido"
                    name="lastName"
                    autoComplete="lname"
                    inputProps={{
                      maxLength: 15
                    }}
                    value={lastname}
                    onChange={(e) => {setLastname(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label="Nombre de usuario"
                    name="usernamer"
                    inputProps={{
                      maxLength: 30,
                      minLength: 3
                    }}
                    value={username}
                    onChange={(e) => {setUsername(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Correo electronico"
                    name="email"
                    autoComplete="email"
                    type='email'
                    inputProps={{
                      maxLength: 200,
                      minLength: 5
                    }}
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="phone"
                    label="Telefono"
                    name="phone"
                    autoComplete="phone"
                    type='number'
                    inputProps={{
                      maxLength: 12
                    }}
                    value={phone}
                    onChange={(e) => {setPhone(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" required className={classes.formControl}>
                  <InputLabel htmlFor="select-profile-label">Perfil</InputLabel>
                  <Select
                    native
                    required
                    fullWidth
                    label="Perfil"
                    inputProps={{
                      name: 'perfil',
                      id: 'select-profile-label',
                    }}
                    value={profile}
                    onChange={(e) => {setProfile(e.target.value)}}
                    >
                    <option aria-label="None" value="" />
                    <ProfileSelect/>
                  </Select>
                </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="_password"
                    label="Contraseña"
                    type="password"
                    id="_password"
                    inputProps={{
                        maxLength: 20,
                        minLength: 4
                    }}
                    value={pass}
                    onChange={(e) => {setPass(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="repassword"
                    label="Confirmar constraseña"
                    type="password"
                    id="repassword"
                    inputProps={{
                        maxLength: 20,
                        minLength: 4
                    }}
                    value={repass}
                    onChange={(e) => {setRePass(e.target.value)}}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Guardar
              </Button>
            </form>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
                Cancel
            </Button>
            {/* <Button onClick={handleClose} color="primary">
                Subscribe
            </Button> */}
            </DialogActions>
        </Dialog>
        </ResponsiveDrawer>
        </Suspense>
        </>
    )
}