import React from 'react'
import { Route, Redirect } from 'react-router'
// import Auth from './Auth'
export function ProtectedRoute({ component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props)=>{
                if(localStorage.getItem('token')){
                    return <Component/>
                }else{
                    return <Redirect to={{ pathname:'/login', state:{ from: props.location} }}/>
                }
            }}
        />
    )
}
