import { useEffect, useState, useRef} from 'react'
import { useParams} from 'react-router-dom'
import axios from 'axios'
import {
    Card,
    CardContent,
    Button,
    CardActions,
    CardHeader,
    IconButton,
    Grid,
    TextField,
    Snackbar,
    FormControl,
    InputLabel,
    Select
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Save from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '../../components/Alert'
import { TruckSelect } from '../../components/Selects/TruckSelect'
import { UserSelect } from '../../components/Selects/UserSelect'
import { QuantityTypeSelect } from '../../components/Selects/QuantityTypeSelect'
import { FuelTypeSelect } from '../../components/Selects/FuelTypeSelect'
import {_myApi} from '../../Api/ApiUrl'
import {ResponsiveDrawer} from '../Layouts/ResponsiveTemplate'

var querystring = require('querystring');

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    Buttonleft:{
        align: 'right'
    },
    formControl: {
        minWidth: '100%',
    }
  });

export function FuelChargeEdit(){
    const classes = useStyles()
    const _isMounted = useRef(true);//check mounted
    
    const params = useRef(useParams())
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [alert, setAlert] = useState("success")
    const [mssg, setMssg] = useState("")

    const [id, setId] = useState("")
    const [quantity,setQuantity] = useState("")
    const [quantityType, setQuantityType] =  useState("")
    const [fuelType, setFuelType] = useState("")
    const [truck,setTruck] = useState("")
    const [user,setUser] = useState("")
    const [price,setPrice] = useState("")

    const handleBackButton = () => { 
        window.history.back()
    };

    const loadData = async() => { 
        console.log(params.current);
        if(params.current.id){
            let paramId = params.current.id;
            await axios.get( _myApi + '/fuelcharge/'+paramId, {
              headers:{ 
                "x-access-token": localStorage.getItem("token")
              }
            }).then(response => {
                    if(_isMounted.current){
                    //   const info = Object.values(response.data.data);
                        if(response.data.status){
                            const info = response.data.data
                            setId(info.id)
                            setQuantity(info.quantity)
                            setQuantityType(info.quantity_type_id)
                            setFuelType(info.fuel_type_id)
                            setTruck(info.truck_id)
                            setUser(info.user_id)
                            setPrice(info.price)
                        }
                    
                    }
                })
        }
        
    };

    const handleUpdate = async(e) => {
        e.preventDefault()
        await axios.put( _myApi + "/fuelcharge/"+id,querystring.stringify({
            quantity: quantity,
            price: price,
            fuel_type: fuelType,
            user: user,
            truck: truck,
            quantity_type: quantityType
        }),{
          headers:{ 
            "Content-Type": "application/x-www-form-urlencoded",
            "x-access-token": localStorage.getItem("token")
          }
        }).then((response) => {
          if(_isMounted.current){
            if(response.data.status === true){
                setAlert('success')
                setMssg('El registro ha sido correctamente actualizado.')
                handleOpenSnackBar()
            }else{
                console.log(response);
                setMssg('Error al actualizar registro.')
                setAlert('error')
                handleOpenSnackBar()
            }
          }
          
        }).catch(err =>{
          console.log(err);
          setMssg('Error al actualizar registro (1).')
          setAlert('error')
          handleOpenSnackBar()

        });
      }

    useEffect(()=>{
        loadData()
        return () => {
            _isMounted.current = false;  // not good
          }
    }, [])

    const handleOpenSnackBar = () => { setOpenSnackBar(true); };
    
    const handleCloseSnackBar = () => { setOpenSnackBar(false); };

    return(
        <>
        <ResponsiveDrawer>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity={alert}>
            {mssg}
          </Alert>
        </Snackbar>
        <Grid container>
            <Grid item xs={12} sm={8}>
            <form id="updateform" className={classes.form} noValidate onSubmit={handleUpdate}>
            <Card className={classes.root}>
                <CardActions>
                    <IconButton 
                        onClick={handleBackButton}
                        className={classes.Buttonleft}
                        >
                        <ArrowBack />
                    </IconButton>
                </CardActions>
                <CardHeader
                    title="Editar carga de combustible"
                />
                <CardContent>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                  <TextField
                    name="Quantity"
                    variant="outlined"
                    required
                    fullWidth
                    id="Quantity"
                    label="Cantidad"
                    autoFocus
                    value={quantity}
                    onChange={(e) => {setQuantity(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-quantity-label">Tipo de cantidad</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="QuantityType"
                      inputProps={{
                        name: 'tipo_cantidad',
                        id: 'select-quantity-label',
                      }}
                      value={quantityType}
                      onChange={(e) => {setQuantityType(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      {/* options list */}
                      <QuantityTypeSelect/>  
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="price"
                    label="Precio ($)"
                    name="price"
                    value={price}
                    onChange={(e) => {setPrice(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-fuel-label">Tipo de combustible</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Combustible"
                      inputProps={{
                        name: 'combustible',
                        id: 'select-fuel-label',
                      }}
                      value={fuelType}
                      onChange={(e) => {setFuelType(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <FuelTypeSelect/>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-truck-label">Vehículo</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Vehículo"
                      inputProps={{
                        name: 'vehículo',
                        id: 'select-truck-label',
                      }}
                      value={truck}
                      onChange={(e) => {setTruck(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <TruckSelect/>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-user-label">Responsable</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Responsable"
                      inputProps={{
                        name: 'Responsable',
                        id: 'select-user-label',
                      }}
                      value={user}
                      onChange={(e) => {setUser(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <UserSelect/>
                    </Select>
                  </FormControl>
                </Grid>                    
                    </Grid>
                    
                </CardContent>
                <CardActions style={{justifyContent: 'right'}} spacing={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        form="updateform"
                        className={classes.submit}
                        startIcon={<Save />}
                        >
                        Guardar
                    </Button>
                </CardActions>
            </Card>
            </form>
        </Grid>
        </Grid>
        </ResponsiveDrawer>
        </>
    )
}