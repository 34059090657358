import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {_myApi} from '../../Api/ApiUrl'

export function FuelTypeSelect() {
    const _isMounted = useRef(true)
    const [fuel, setFuel] = useState([])

    useEffect( () => {

        const getFuel= async () =>{
            await axios.get( _myApi + '/fueltype', {
                headers:{ 
                  "x-access-token": localStorage.getItem("token")
                }
              }).then(response => {
                    if(response){
                        setFuel(response.data.data)
                    }
                }).catch(err => {
                    console.log(err);
                })
        }
        getFuel()
      
        return () => {
            _isMounted.current = false;  
          }
    }, [] )

    return (
        <>
            {fuel.map( res =>{
               return <option key={res.id} value={res.id}>{res.name}</option>
            })}
        </>
    )
}