import { useEffect, useState } from "react";
import { Redirect } from 'react-router-dom'

export function Logout(){
    const [state, setState] = useState(true)
    useEffect(()=>{
        if(localStorage.getItem("token")){
            localStorage.removeItem("token")
        }
        setState(false)
    },[])

    return state
    ? <div>Checkout ...</div>
    : <Redirect to='/login'/>
    
}