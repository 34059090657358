import { useEffect, useState, useRef} from 'react'
import { useParams} from 'react-router-dom'
import axios from 'axios'
import {
    Card,
    CardContent,
    Button,
    CardActions,
    CardHeader,
    IconButton,
    Grid,
    TextField,
    Snackbar,
    FormControl,
    InputLabel,
    Select
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Save from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '../../components/Alert'
import { TruckSelect } from '../../components/Selects/TruckSelect'
import { CountrySelect } from '../../components/Selects/CountrySelect'
import { StateSelect } from '../../components/Selects/StateSelect'
import { CitySelect } from '../../components/Selects/CitySelect'
import {_myApi} from '../../Api/ApiUrl'
import {ResponsiveDrawer} from '../Layouts/ResponsiveTemplate'

var querystring = require('querystring');

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    Buttonleft:{
        align: 'right'
    },
    formControl: {
        minWidth: '100%',
    }
  });

export function TripEdit(){
    const classes = useStyles()
    const _isMounted = useRef(true);//check mounted
    
    const params = useRef(useParams())
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [alert, setAlert] = useState("success")
    const [mssg, setMssg] = useState("")

    const [id, setId] = useState("")
    const [truck,setTruck] = useState("")
    const [country,setCountry] = useState("")
    const [countryDestino,setCountryDestino] = useState("")
    const [states,setStates] = useState("")
    const [statesDestino,setStatesDestino] = useState("")
    const [cities,setCities] = useState("")
    const [citiesDestino,setCitiesDestino] = useState("")
    const [load, setLoad] = useState("")

    const [origen,setOrigen] = useState("")
    const [destino,setDestino] = useState("")
    const [distance,setDistance] = useState("")
    const [time,setTime] = useState("")


    const handleBackButton = () => { 
        window.history.back()
    };

    const loadData = async() => { 
        console.log(params.current);
        if(params.current.id){
            let paramId = params.current.id;
            await axios.get( _myApi + '/trip/'+paramId, {
                headers:{ 
                  "x-access-token": localStorage.getItem("token")
                }
              }).then(response => {
                    if(_isMounted.current){
                        if(response.data.status){
                            const info = response.data.data
                            setId(info.id)
                            setOrigen(info.from.adress)
                            setDestino(info.to.adress)
                            setCountry(info.from.city.state.country_id)
                            setCountryDestino(info.to.city.state.country_id)
                            setStates(info.from.city.state.id)
                            setStatesDestino(info.to.city.state.id)
                            setCities(info.from.city.id)
                            setCitiesDestino(info.to.city.id)
                            setDistance(info.estimated_distance)
                            setTime(info.estimated_time)
                            setTruck(info.truck_id)
                            setLoad(info.truck.current_load)
                        }
                    
                    }
                })
        }
        
    };

    const handleUpdate = async(e) => {
        e.preventDefault()
        await axios.put( _myApi + "/trip/"+id,querystring.stringify({
            address_from: origen,
            address_to: destino,
            city_from: cities,
            city_to: citiesDestino,
            distance: distance,
            time: time,
            truck_id: truck,
            load: load
        }),{
          headers:{ 
            "Content-Type": "application/x-www-form-urlencoded",
            "x-access-token": localStorage.getItem("token")
          }
        }).then((response) => {
          if(_isMounted.current){
            if(response.data.status === true){
                setAlert('success')
                setMssg('El viaje ha sido correctamente actualizado.')
                handleOpenSnackBar()
            }else{
                console.log(response);
                setMssg('Error al actualizar datos del viaje.')
                setAlert('error')
                handleOpenSnackBar()
            }
          }
          
        }).catch(err =>{
          console.log(err);
          setMssg('Error al actualizar datos del viaje.')
          setAlert('error')
          handleOpenSnackBar()

        });
      }

    useEffect(()=>{
        loadData()
        return () => {
            _isMounted.current = false;  // not good
          }
    }, [])

    const handleOpenSnackBar = () => { setOpenSnackBar(true); };
    
    const handleCloseSnackBar = () => { setOpenSnackBar(false); };

    return(
        <>
        <ResponsiveDrawer>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity={alert}>
            {mssg}
          </Alert>
        </Snackbar>
        <Grid container>
            <Grid item xs={12} sm={8}>
            <form id="updateform" autoComplete="off" className={classes.form} noValidate onSubmit={handleUpdate}>
            <Card className={classes.root}>
                <CardActions>
                    <IconButton 
                        onClick={handleBackButton}
                        className={classes.Buttonleft}
                        >
                        <ArrowBack />
                    </IconButton>
                </CardActions>
                <CardHeader
                    title="Editar Viaje"
                />
                <CardContent>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <TextField
                        name="Origen"
                        variant="outlined"
                        required
                        placeholder="Ej: Dirección #2020, depto 12, block 1"
                        fullWidth
                        id="Origen"
                        label="Dirección de Origen"
                        autoFocus
                        value={origen}
                        onChange={(e) => {setOrigen(e.target.value)}}
                    />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="select-country-label">País</InputLabel>
                        <Select
                        native
                        required
                        fullWidth
                        label="Pais"
                        inputProps={{
                            name: 'pais',
                            id: 'select-country-label',
                        }}
                        value={country}
                        onChange={(e) => {setCountry(e.target.value)}}
                        >
                        <option aria-label="None" value="" />
                        <CountrySelect/>
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="select-state-label">Estado/Región</InputLabel>
                        <Select
                        native
                        required
                        fullWidth
                        label="Estado"
                        inputProps={{
                            name: 'estado',
                            id: 'select-state-label',
                        }}
                        value={states}
                        onChange={(e) => {setStates(e.target.value)}}
                        >
                        <option aria-label="None" value="" />
                        <StateSelect countryid={country}/>
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="select-city-label">Ciudad</InputLabel>
                        <Select
                        native
                        required
                        fullWidth
                        label="Ciudad"
                        inputProps={{
                            name: 'ciudad',
                            id: 'select-city-label',
                        }}
                        value={cities}
                        onChange={(e) => {setCities(e.target.value)}}
                        >
                        <option aria-label="None" value="" />
                        <CitySelect stateid={states}/>
                        </Select>
                    </FormControl>
                    </Grid>
                    {/* direcciòn de destino */}
                    <Grid item xs={12}>
                    <TextField
                        name="Destino"
                        variant="outlined"
                        required
                        placeholder="Ej: Dirección #2020, depto 12, block 1"
                        fullWidth
                        id="Destino"
                        label="Dirección de Destino"
                        autoFocus
                        value={destino}
                        onChange={(e) => {setDestino(e.target.value)}}
                    />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="select-country-destino-label">País</InputLabel>
                        <Select
                        native
                        required
                        fullWidth
                        label="Pais-destino"
                        inputProps={{
                            name: 'pais-destino',
                            id: 'select-country-destino-label',
                        }}
                        value={countryDestino}
                        onChange={(e) => {setCountryDestino(e.target.value)}}
                        >
                        <option aria-label="None" value="" />
                        <CountrySelect/>
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="select-state-destino-label">Estado/Región</InputLabel>
                        <Select
                        native
                        required
                        fullWidth
                        label="Estado-destino"
                        inputProps={{
                            name: 'estado-destino',
                            id: 'select-state-destino-label',
                        }}
                        value={statesDestino}
                        onChange={(e) => {setStatesDestino(e.target.value)}}
                        >
                        <option aria-label="None" value="" />
                        <StateSelect countryid={countryDestino}/>
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="select-city-destino-label">Ciudad</InputLabel>
                        <Select
                        native
                        required
                        fullWidth
                        label="Ciudad-destino"
                        inputProps={{
                            name: 'ciudad-destino',
                            id: 'select-city-destino-label',
                        }}
                        value={citiesDestino}
                        onChange={(e) => {setCitiesDestino(e.target.value)}}
                        >
                        <option aria-label="None" value="" />
                        <CitySelect stateid={statesDestino}/>
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        id="distance"
                        label="Distancia estimada (Km)"
                        name="distance"
                        required
                        type='number'
                        value={distance}
                        onChange={(e) => {setDistance(e.target.value)}}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        id="time"
                        required
                        label="Tiempo estimado"
                        name="time"
                        value={time}
                        onChange={(e) => {setTime(e.target.value)}}
                    />
                    </Grid>                    
                    <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="select-truck-label">Vehículo</InputLabel>
                        <Select
                        native
                        required
                        fullWidth
                        label="Vehículo"
                        inputProps={{
                            name: 'vehículo',
                            id: 'select-truck-label',
                        }}
                        value={truck}
                        onChange={(e) => {setTruck(e.target.value)}}
                        >
                        <option aria-label="None" value="" />
                        <TruckSelect/>
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        id="load"
                        required
                        type='number'
                        label="Carga (kg)"
                        name="load"
                        value={load}
                        onChange={(e) => {setLoad(e.target.value)}}
                    />
                    </Grid>                    
                </Grid>
                    
                </CardContent>
                <CardActions style={{justifyContent: 'right'}} spacing={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        form="updateform"
                        className={classes.submit}
                        startIcon={<Save />}
                        >
                        Guardar
                    </Button>
                </CardActions>
            </Card>
            </form>
        </Grid>
        </Grid>
        </ResponsiveDrawer>
        </>
    )
}