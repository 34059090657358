import { useEffect, useState, useRef, lazy, Suspense} from 'react'
import { useParams} from 'react-router-dom'
import axios from 'axios'
import {
    Card,
    CardContent,
    Button,
    CardActions,
    CardHeader,
    IconButton,
    Grid,
    TextField,
    InputLabel,
    FormControl,
    Select,
    Snackbar
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Save from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles';
// import { ProfileSelect } from '../../components/Selects/ProfileSelect'
import { Alert } from '../../components/Alert'
import {_myApi} from '../../Api/ApiUrl'
import {ResponsiveDrawer} from '../Layouts/ResponsiveTemplate'
const ProfileSelect = lazy(()=> import('../../components/Selects/ProfileSelect'))
var querystring = require('querystring');

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    Buttonleft:{
        align: 'right'
    },
    formControl: {
        minWidth: '100%',
    }
  });

export function UserEdit(){
    const classes = useStyles()
    const _isMounted = useRef(true);//check mounted

    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [alert, setAlert] = useState("success")
    const [mssg, setMssg] = useState("")
    const params = useRef(useParams())

    const [id, setId] = useState("")
    const [name,setName] = useState("")
    const [username,setUsername] = useState("")
    const [email,setEmail] = useState("")
    const [phone,setPhone] = useState("")
    const [pass,setPass] = useState("")
    const [repass,setRePass] = useState("")
    const [profile,setProfile] = useState("")

    const handleBackButton = () => { 
        window.history.back()
    };

    const loadData = async() => { 
        console.log(params.current);
        if(params.current.id){
            let paramId = params.current.id;
            await axios.get( _myApi + '/user/'+paramId, {
                headers:{ 
                  "x-access-token": localStorage.getItem("token")
                }
              }).then(response => {
                    if(_isMounted.current){
                    //   const info = Object.values(response.data.data);
                        if(response.data.status){
                            const info = response.data.data
                            console.log(info.id);
                            setId(info.id)
                            setName(info.fullname)
                            setUsername(info.user)
                            setEmail(info.email)
                            setPhone(info.phone)
                            setProfile(info.profiles.id)
                        }
                    
                    }
                })
        }
        
    };

    const handleUpdate = async(e) => {
        e.preventDefault()
        await axios.put( _myApi + "/user/"+id,querystring.stringify({
          name: name,
          username: username,
          email: email,
          phone: phone,
          password: pass,
          repassword: repass,
          profile: profile
        }),{
          headers:{ 
            "Content-Type": "application/x-www-form-urlencoded",
            "x-access-token": localStorage.getItem("token")
          }
        }).then((response) => {
            if(_isMounted.current){
              if(response.data.status === true){
                  setAlert('success')
                  setMssg('El viaje ha sido correctamente actualizado.')
                  handleOpenSnackBar()
              }else{
                  console.log(response);
                  setMssg('Error al actualizar datos del viaje.')
                  setAlert('error')
                  handleOpenSnackBar()
              }
            }
            
          }).catch(err =>{
            console.log(err);
            setMssg('Error al actualizar datos del viaje.')
            setAlert('error')
            handleOpenSnackBar()
  
          });
      }

    useEffect(()=>{
        loadData()
        return () => {
            _isMounted.current = false;  // not good
          }
    }, [])

    const handleOpenSnackBar = () => { setOpenSnackBar(true); };
    
    const handleCloseSnackBar = () => { setOpenSnackBar(false); };

    return(
        <>
        <Suspense fallback={<div>loadin..</div>}>
        <ResponsiveDrawer>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity={alert}>
            {mssg}
          </Alert>
        </Snackbar>
        <Grid container>
            <Grid item xs={12} sm={8}>
            <Card className={classes.root}>
                <CardActions>
                    <IconButton 
                        onClick={handleBackButton}
                        className={classes.Buttonleft}
                        >
                        <ArrowBack />
                    </IconButton>
                </CardActions>
                <CardHeader
                    title="Editar usuario"
                />
                <CardContent>
                <form id="updateform" className={classes.form} onSubmit={handleUpdate}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="fname"
                            name="firstName"
                            variant="outlined"
                            required
                            fullWidth
                            id="firstName"
                            label="Nombre y Apellido"
                            autoFocus
                            inputProps={{
                                maxLength: 30
                              }}
                            value={name}
                            onChange={(e) => {setName(e.target.value)}}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="username"
                            label="Nombre de usuario"
                            name="usernamer"
                            inputProps={{
                                maxLength: 30,
                                minLength: 3
                              }}
                            value={username}
                            onChange={(e) => {setUsername(e.target.value)}}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Correo electronico"
                            name="email"
                            autoComplete="email"
                            type='email'
                            inputProps={{
                                maxLength: 200,
                                minLength: 5
                              }}
                            value={email}
                            onChange={(e) => {setEmail(e.target.value)}}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            id="phone"
                            label="Telefono"
                            name="phone"
                            autoComplete="phone"
                            inputProps={{
                                maxLength: 12
                              }}
                            type='number'
                            value={phone}
                            onChange={(e) => {setPhone(e.target.value)}}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="select-profile-label">Perfil</InputLabel>
                        <Select
                            native
                            required
                            fullWidth
                            label="Perfil"
                            inputProps={{
                            name: 'perfil',
                            id: 'select-profile-label',
                            }}
                            value={profile}
                            onChange={(e) => {setProfile(e.target.value)}}
                            >
                            <option aria-label="None" value="" />
                            
                            <ProfileSelect/>
                        </Select>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            inputProps={{
                                maxLength: 20,
                                minLength: 4
                            }}
                            value={pass}
                            onChange={(e) => {setPass(e.target.value)}}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="repassword"
                            label="Confirmar constraseña"
                            type="password"
                            id="repassword"
                            inputProps={{
                                maxLength: 20,
                                minLength: 4
                            }}
                            value={repass}
                            onChange={(e) => {setRePass(e.target.value)}}
                        />
                        </Grid>
                    </Grid>
                    
                    </form>
                </CardContent>
                <CardActions style={{justifyContent: 'right'}} spacing={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        form="updateform"
                        className={classes.submit}
                        startIcon={<Save />}
                    >
                        Guardar
                    </Button>
                </CardActions>
            </Card>
        </Grid>
        </Grid>
        </ResponsiveDrawer>
        </Suspense>
        </>
    )
}