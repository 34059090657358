import { forwardRef, useEffect, useState, useRef } from "react"
import axios from 'axios'

import { 
  Button, 
  TextField, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  Grid,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  useTheme
} from '@material-ui/core'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Alert } from '../../components/Alert'
import { TruckSelect } from '../../components/Selects/TruckSelect'
import { CountrySelect } from '../../components/Selects/CountrySelect'
import { StateSelect } from '../../components/Selects/StateSelect'
import { CitySelect } from '../../components/Selects/CitySelect'
import { useHistory } from "react-router"
import {_myApi} from '../../Api/ApiUrl'
import {ResponsiveDrawer} from '../Layouts/ResponsiveTemplate'

var querystring = require('querystring');

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
const columnas=[
    {
        title:'ID',
        field:'id'
    },{
        title:'Origen',
        field:'from.adress'
    },{
        title:'Destino',
        field:'to.adress'
    },{
        title:'Vehículo',
        field:'truck.name'
    },{
        title:'Patente',
        field:'truck.truck_id'
    },{
        title:'Distancia estimada',
        field:'estimated_distance'
    },{
        title:'Tiempo estimado',
        field:'estimated_time'
    }
];

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function TripList() {
    const classes = useStyles();
    const _isMounted = useRef(true);//check mounted
    const history = useHistory()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [alert, setAlert] = useState("success")
    const [mssg, setMssg] = useState("")

    const [truck,setTruck] = useState("")
    const [country,setCountry] = useState("")
    const [countryDestino,setCountryDestino] = useState("")
    const [states,setStates] = useState("")
    const [statesDestino,setStatesDestino] = useState("")
    const [cities,setCities] = useState("")
    const [citiesDestino,setCitiesDestino] = useState("")
    const [load, setLoad] = useState("")

    const [origen,setOrigen] = useState("")
    const [destino,setDestino] = useState("")
    const [distance,setDistance] = useState("")
    const [time,setTime] = useState("")

    const getAllTruck = async() => {
        await axios.get( _myApi + '/trip', {
          headers:{ 
            "x-access-token": localStorage.getItem("token")
          }
        }).then(response => {
              if(_isMounted.current){
                const info = Object.values(response.data.data);
                setData(info);
              }
            })
    }

    const handleCreateTrip = async(e) => {
      e.preventDefault()
      await axios.post( _myApi + "/trip",querystring.stringify({
        address_from: origen,
        address_to: destino,
        city_from: cities,
        city_to: citiesDestino,
        distance: distance,
        time: time,
        truck_id: truck,
        load: load
      }),{
        headers:{ 
          "Content-Type": "application/x-www-form-urlencoded",
          "x-access-token": localStorage.getItem("token")
        }
      }).then((response) => {
        if(_isMounted.current){
          if(response.data.status === true){
            getAllTruck();
            handleCloseDialog();
            setMssg('El registro ha sido creado exitosamente.')
            setAlert('success')
            handleOpenSnackBar();

            setOrigen("")
            setDestino("")
            setCountry("")
            setCountryDestino("")
            setStates("")
            setStatesDestino("")
            setCities("")
            setCitiesDestino("")
            setDistance("")
            setTime("")
            setTruck("")
            setLoad("")
          }else{
            setMssg('Error al crear el registro.')
            setAlert('error')
            handleOpenSnackBar();
          }
        }
      }).catch(err =>{
        console.log(err);
        setMssg('Error al crear el registro.')
        setAlert('error')
        handleOpenSnackBar();
      });
    }
    useEffect(() => {
        getAllTruck();
        return () => {
          _isMounted.current = false;  // not good
        }
    },[])

    const handleClickOpenDialog = () => { setOpenDialog(true); };
    
    const handleCloseDialog = () => { setOpenDialog(false); };

    const handleOpenSnackBar = () => { setOpenSnackBar(true); };
    
    const handleCloseSnackBar = () => { setOpenSnackBar(false); };

    const handleOnRowClick = (event, rowData) => { 
      history.push({pathname:'/trip/'+rowData.id, data: rowData})
    };

   
    return(
        <> 
        <ResponsiveDrawer>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity={alert}>
            {mssg}
          </Alert>
        </Snackbar>
        <MaterialTable
        title=" "
        icons={tableIcons}
        columns={columnas}
        data={data}
        onRowClick={handleOnRowClick}
        actions={[
            {
              icon: () => <AddBox color="primary"/>,
              tooltip: "Crear Vehiculo",
              position: "toolbar",
              onClick: () => {
                handleClickOpenDialog();
              }
            }
          ]}
        
        />
        {/* Create Modal */}
        <Dialog fullScreen={fullScreen} open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Crear Viaje</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Complete el siguiente formulario para poder crear un Vehiculo
            </DialogContentText>
            <form className={classes.form} onSubmit={handleCreateTrip}>
              <Grid container spacing={2}>
                {/* direcciòn de origen */}
                <Grid item xs={12}>
                  <TextField
                    name="Origen"
                    variant="outlined"
                    required
                    placeholder="Ej: Dirección #2020, depto 12, block 1"
                    fullWidth
                    id="Origen"
                    label="Dirección de Origen"
                    autoFocus
                    value={origen}
                    onChange={(e) => {setOrigen(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-country-label">País</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Pais"
                      inputProps={{
                        name: 'pais',
                        id: 'select-country-label',
                      }}
                      value={country}
                      onChange={(e) => {setCountry(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <CountrySelect/>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-state-label">Estado/Región</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Estado"
                      inputProps={{
                        name: 'estado',
                        id: 'select-state-label',
                      }}
                      value={states}
                      onChange={(e) => {setStates(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <StateSelect countryid={country}/>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-city-label">Ciudad</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Ciudad"
                      inputProps={{
                        name: 'ciudad',
                        id: 'select-city-label',
                      }}
                      value={cities}
                      onChange={(e) => {setCities(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <CitySelect stateid={states}/>
                    </Select>
                  </FormControl>
                </Grid>
                {/* direcciòn de destino */}
                <Grid item xs={12}>
                  <TextField
                    name="Destino"
                    variant="outlined"
                    required
                    placeholder="Ej: Dirección #2020, depto 12, block 1"
                    fullWidth
                    id="Destino"
                    label="Dirección de Destino"
                    autoFocus
                    value={destino}
                    onChange={(e) => {setDestino(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-country-destino-label">País</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Pais-destino"
                      inputProps={{
                        name: 'pais-destino',
                        id: 'select-country-destino-label',
                      }}
                      value={countryDestino}
                      onChange={(e) => {setCountryDestino(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <CountrySelect/>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-state-destino-label">Estado/Región</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Estado-destino"
                      inputProps={{
                        name: 'estado-destino',
                        id: 'select-state-destino-label',
                      }}
                      value={statesDestino}
                      onChange={(e) => {setStatesDestino(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <StateSelect countryid={countryDestino}/>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-city-destino-label">Ciudad</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Ciudad-destino"
                      inputProps={{
                        name: 'ciudad-destino',
                        id: 'select-city-destino-label',
                      }}
                      value={citiesDestino}
                      onChange={(e) => {setCitiesDestino(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <CitySelect stateid={statesDestino}/>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    type="number"
                    id="distance"
                    label="Distancia estimada (Km)"
                    name="distance"
                    value={distance}
                    onChange={(e) => {setDistance(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="time"
                    required
                    label="Tiempo estimado"
                    name="time"
                    type='time'
                    value={time}
                    onChange={(e) => {setTime(e.target.value)}}
                  />
                </Grid>                    
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-truck-label">Vehículo</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Vehículo"
                      inputProps={{
                        name: 'vehículo',
                        id: 'select-truck-label',
                      }}
                      value={truck}
                      onChange={(e) => {setTruck(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <TruckSelect/>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    id="load"
                    type="number"
                    label="Carga (kg)"
                    name="load"
                    value={load}
                    onChange={(e) => {setLoad(e.target.value)}}
                  />
                </Grid> 
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Guardar
              </Button>
            </form>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
                Cancel
            </Button>
            {/* <Button onClick={handleClose} color="primary">
                Subscribe
            </Button> */}
            </DialogActions>
        </Dialog>
        </ResponsiveDrawer>
        </>
    )
}