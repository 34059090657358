import { forwardRef, useEffect, useState, useRef } from "react"
import axios from 'axios'

import { 
  Button, 
  TextField, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  Grid,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  useTheme
} from '@material-ui/core'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Alert } from '../../components/Alert'
import { TruckSelect } from '../../components/Selects/TruckSelect'
import { UserSelect } from '../../components/Selects/UserSelect'
import { QuantityTypeSelect } from '../../components/Selects/QuantityTypeSelect'
import { FuelTypeSelect } from '../../components/Selects/FuelTypeSelect'
import { useHistory } from "react-router"
import {_myApi} from '../../Api/ApiUrl'
import {ResponsiveDrawer} from '../Layouts/ResponsiveTemplate'

var querystring = require('querystring');

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
const columnas=[
    {
        title:'ID',
        field:'id'
    },{
        title:'Cantidad',
        field:'quantity'
    },{
        title:'Tipo de cantidad',
        field:'quantity_type.name'
    },{
        title:'Combustible',
        field:'fuel_type.name'
    },{
        title:'Precio ($)',
        field:'price'
    },{
        title:'Vehículo',
        field:'truck.name'
    },{
        title:'Patente',
        field:'truck.truck_id'
    },{
        title:'Responsable',
        field:'user.fullname'
    },{
        title:'Fecha',
        field:'datetime'
    }
];

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function FuelChargeList() {
    const classes = useStyles();
    const _isMounted = useRef(true);//check mounted
    const history = useHistory()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [alert, setAlert] = useState("success")
    const [mssg, setMssg] = useState("")

    const [quantity,setQuantity] = useState("")
    const [quantityType, setQuantityType] =  useState("")
    const [fuelType, setFuelType] = useState("")
    const [truck,setTruck] = useState("")
    const [user,setUser] = useState("")
    const [price,setPrice] = useState("")

    const getAllFuelCharge = async() => {
        await axios.get( _myApi + '/fuelcharge', {
          headers:{ 
            "x-access-token": localStorage.getItem("token")
          }
        }).then(response => {
              if(_isMounted.current){
                const info = Object.values(response.data.data);
                setData(info);
              }
            })
    }

    const handleCreateFuelcharge = async(e) => {
      e.preventDefault()
      await axios.post( _myApi + "/fuelcharge",querystring.stringify({
        quantity: quantity,
        price: price,
        fuel_type: fuelType,
        user: user,
        truck: truck,
        quantity_type: quantityType
      }),{
        headers:{ 
          "Content-Type": "application/x-www-form-urlencoded",
          "x-access-token": localStorage.getItem("token")
        }
      }).then((response) => {
        if(_isMounted.current){
          if(response.data.status === true){
            getAllFuelCharge();
            handleCloseDialog();
            setMssg('El registro ha sido creado exitosamente.')
            setAlert('success')
            handleOpenSnackBar();

            setQuantity("")
            setQuantityType("")
            setFuelType("")
            setTruck("")
            setUser("")
            setPrice("")
          }else{
            setMssg('Error al crear el registro.')
            setAlert('error')
            handleOpenSnackBar();
          }
        }
      }).catch(err =>{
        console.log(err);
        setMssg('Error al crear el registro.')
        setAlert('error')
        handleOpenSnackBar();
      });
    }
    useEffect(() => {
        getAllFuelCharge();
        
        return () => {
          _isMounted.current = false;  // not good
        }
    },[])

    const handleClickOpenDialog = () => { setOpenDialog(true); };
    
    const handleCloseDialog = () => { setOpenDialog(false); };

    const handleOpenSnackBar = () => { setOpenSnackBar(true); };
    
    const handleCloseSnackBar = () => { setOpenSnackBar(false); };

    const handleOnRowClick = (event, rowData) => { 
        history.push({pathname:'/fuelcharge/'+rowData.id, data: rowData})
      };

    
    return(
        <> 
        <ResponsiveDrawer>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity={alert}>
            {mssg}
          </Alert>
        </Snackbar>
        <MaterialTable
        title=" "
        icons={tableIcons}
        columns={columnas}
        data={data}
        onRowClick={handleOnRowClick}
        actions={[
            {
              icon: () => <AddBox fontSize="large" color="primary"/>,
              tooltip: "Crear Vehiculo",
              position: "toolbar",
              onClick: () => {
                handleClickOpenDialog();
              }
            }
          ]}
        
        />
        {/* Create Modal */}
        <Dialog fullScreen={fullScreen} open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Crear Reparación</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Complete el siguiente formulario para poder crear un registro de reparación
            </DialogContentText>
            <form className={classes.form} onSubmit={handleCreateFuelcharge}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="Quantity"
                    variant="outlined"
                    required
                    fullWidth
                    id="Quantity"
                    label="Cantidad"
                    autoFocus
                    type='number'
                    value={quantity}
                    onChange={(e) => {setQuantity(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-quantity-label">Tipo de cantidad</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="QuantityType"
                      inputProps={{
                        name: 'tipo_cantidad',
                        id: 'select-quantity-label',
                      }}
                      value={quantityType}
                      onChange={(e) => {setQuantityType(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      {/* options list */}
                      <QuantityTypeSelect/>  
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="price"
                    label="Precio ($)"
                    name="price"
                    type='number'
                    value={price}
                    onChange={(e) => {setPrice(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-fuel-label">Tipo de combustible</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Combustible"
                      inputProps={{
                        name: 'combustible',
                        id: 'select-fuel-label',
                      }}
                      value={fuelType}
                      onChange={(e) => {setFuelType(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <FuelTypeSelect/>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-truck-label">Vehículo</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Vehículo"
                      inputProps={{
                        name: 'vehículo',
                        id: 'select-truck-label',
                      }}
                      value={truck}
                      onChange={(e) => {setTruck(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <TruckSelect/>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="select-user-label">Responsable</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      label="Responsable"
                      inputProps={{
                        name: 'Responsable',
                        id: 'select-user-label',
                      }}
                      value={user}
                      onChange={(e) => {setUser(e.target.value)}}
                      >
                      <option aria-label="None" value="" />
                      <UserSelect/>
                    </Select>
                  </FormControl>
                </Grid>
            </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Guardar
              </Button>
            </form>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
                Cancel
            </Button>
            {/* <Button onClick={handleClose} color="primary">
                Subscribe
            </Button> */}
            </DialogActions>
        </Dialog>
        </ResponsiveDrawer>
        </>
    )
}