import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {_myApi} from '../../Api/ApiUrl'

export function StateSelect({countryid}) {
    const _isMounted = useRef(true)
    const [state,setState] = useState([])
    const [country, setCountry] = useState("")

    useEffect( () => {
        if(countryid){
            setCountry(countryid)
        }

        const getState= async () =>{
            await axios.get( _myApi + '/state/'+ country, {
                headers:{ 
                  "x-access-token": localStorage.getItem("token")
                }
              }).then(response => {
                    if(response){
                        setState(response.data.data)
                    }
                }).catch(err => {
                    console.log(err);
                })
        }
        if(country){
            getState()
        }
        return () => {
            _isMounted.current = false;  
          }
    }, [country,countryid] )

    return (
        <>
            {state.map( res =>{
               return <option key={res.id} value={res.id}>{res.name}</option>
            })}
        </>
    )
}