import React, { Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom'

import { Login } from './pages/Login/Login'
import { Logout } from './pages/Login/Logout'
import { Dashboard } from './pages/Dashboard/Dashboard'
import { NotFound }from './pages/NotFound/NotFound'

// import { ResponsiveDrawer } from './pages/Layouts/ResponsiveTemplate'
// import { Template } from './pages/Layouts/Template'
import { UserList, UserEdit } from './pages/User/Index'
import { TruckList, TruckEdit } from './pages/Truck/Index'
import { TripList, TripEdit } from './pages/Trip/Index'
import { ChangeComponentsList, ChangeComponentEdit } from './pages/ChangeComponents/Index'
import { FuelChargeList, FuelChargeEdit } from './pages/FuelCharge/Index'
import { Profile } from './pages/MyProfile/Index'
import { ProtectedRoute } from './ProtectedRoute'

const NotFoundRedirect = () => <Redirect to='/not-found' />

class App extends Component {
  
  render() {
      return (
          
          <Switch>
              <Route exact path='/login' component={Login}></Route>
              <Route exact path='/logout' component={Logout}></Route>
              <Route exact path='/not-found' component={NotFound}></Route>
              
              <ProtectedRoute exact path='/' component={Dashboard}/>
              {/* User */}
              <ProtectedRoute exact path='/user'  component={UserList}></ProtectedRoute>
              <ProtectedRoute exact path='/user/:id'  component={UserEdit}></ProtectedRoute>
              {/* Truck */}
              <ProtectedRoute exact path='/truck'  component={TruckList}></ProtectedRoute>
              <ProtectedRoute exact path='/truck/:id'  component={TruckEdit}></ProtectedRoute>
              {/* Trip*/}
              <ProtectedRoute exact path='/trip'  component={TripList}></ProtectedRoute>
              <ProtectedRoute exact path='/trip/:id'  component={TripEdit}></ProtectedRoute>
              {/* change components */}
              <ProtectedRoute exact path='/changecomponents'  component={ChangeComponentsList}></ProtectedRoute>
              <ProtectedRoute exact path='/changecomponents/:id'  component={ChangeComponentEdit}></ProtectedRoute>
              {/* fuel charge */}
              <ProtectedRoute exact path='/fuelcharge'  component={FuelChargeList}></ProtectedRoute>
              <ProtectedRoute exact path='/fuelcharge/:id'  component={FuelChargeEdit}></ProtectedRoute>
              {/* profile */}
              <ProtectedRoute exact path='/myprofile'  component={Profile}></ProtectedRoute>
            
              <Route component={NotFoundRedirect}></Route>
          </Switch>
      )
    }
}

export default App;